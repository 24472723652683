import { request } from "@/utils";
export const getDetailByUser = (id: string): Promise<any> => {
  return request({
    url: `/admin-server/vehicleRescue/detailByUser/${id}`,
    method: "get",
  });
}

export const getRoutingAndPosition = (name: string): Promise<any> => {
  return request({
    url: `/client-server/getVehicle/routingAndPosition/${name}`,
    method: "get",
  });
}

export const uploadImage = (data: any): Promise<any> => {
  return request({
    url: "/admin-server/vehicleRescue/uploadPicture",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};

export const startRescue = (data: any): Promise<any> => {
  return request({
    url: "/admin-server/vehicleRescue/startRescue",
    method: "PUT",
    data,
  });
};

export const getTakeroverReasonList = (type: string): Promise<any> => {
  return request.get(`/admin-server/dataDictionary?pageSize=0&type=${type}`)
}

export const completeRescue = (data: any): Promise<any> => {
  return request({
    url: "/admin-server/vehicleRescue/completeRescue",
    method: "PUT",
    data,
  });
};

export const getRescueTime = (data: any): Promise<any> => {
  return request({
    url: `/admin-server/vehicleRescue/startNavigate`,
    method: "post",
    data
  });
}
export const getUserPermissions = async () =>
  request.get<any, { data: string[] }>('/admin-server/user/permissions');
