import backIcon from '@/assets/img/home/back.png';
import { NavBar, NavBarProps } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';

interface HeaderProps extends NavBarProps {
  title?: React.ReactNode;
}

const Header = ({ title, right, ...others }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <>
      <NavBar
        backArrow={
          <img
            src={backIcon}
            alt="1"
            style={{
              width: '23px',
              height: '23px',
              margin: 'auto 0',
              display: 'block',
            }}
          />
        }
        right={right}
        onBack={() => navigate(-1)}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 2,
        }}
        {...others}
      >
        <span
          style={{
            fontSize: '18px',
          }}
        >
          {title}
        </span>
      </NavBar>
    </>
  );
};

export default Header;
