import zauth from '@zelos/oauth';
import Axios from 'axios';
import qs from 'qs';
import { Toast } from 'zarm';
import { getStorage } from '.';

const baseURL = process.env.REACT_APP_API_BASE;

const request = zauth
  .extend(Axios, {
    client_id: process.env.REACT_APP_CLIENT_ID!,
    client_secret: process.env.REACT_APP_CLIENT_SECRET!,
    redirect_uri: `${window.location.protocol}//${window.location.host}`,
    host: process.env.REACT_APP_API_BASE_AUTH!,
  })
  .create({
    baseURL,
    timeout: 1000 * 60,
  });

request.interceptors.response.use((response) => {
  const { success, isSuccess, data, message: msg } = response.data;
  if (success || isSuccess) {
    return { data, success, isSuccess } as any;
  }

  const { messageFree } = response.config as any;

  if (!messageFree) {
    Toast.show(msg);
  }

  return Promise.reject(response.data);
});

// request.interceptors.request.use((config) => {
//   config.headers = {
//     ...config.headers,
//     withcredentials: true,
//   };
//   if (getStorage('auth_token')) {
//     config.headers['authorization'] = `Bearer ${getStorage('auth_token')}`;
//   }
//   if (config.url?.includes('oauth')) {
//     config.baseURL = process.env.REACT_APP_API_BASE_AUTH;
//   }
//   return config;
// });

// request.interceptors.response.use(
//   function (response) {
//     const { messageFree, url } = response.config as any;
//     if (url?.includes('oauth')) {
//       return response.data;
//     } else {
//       const { success, data, message, errorCode } = response.data;
//       if (success) {
//         return { data, success, errorCode };
//       } else {
//         if (response.data.errorCode === 'COM0011') {
//           removeStorage('auth_token');
//           const config = response.config;
//           if (!isRefreshing) {
//             isRefreshing = true;
//             return new Promise((resolve) => {
//               getRefreshTokenFunc()
//                 .then((res) => {
//                   if (res?.errorCode === 'COM0011') {
//                     logout();
//                   } else {
//                     const { access_token } = res;
//                     setStorage('auth_token', access_token);
//                     retryRequests.forEach((cb: () => any) => cb());
//                     resolve(request(config));
//                   }
//                 })
//                 .catch((e) => {
//                   console.log(e, '???????');
//                   logout();
//                 })
//                 .finally(() => {
//                   retryRequests = [];
//                   isRefreshing = false;
//                 });
//             });
//           } else {
//             return new Promise((resolve) => {
//               retryRequests.push(() => resolve(request(config)));
//             });
//           }
//         } else {
//           if (messageFree) {
//             return { data, success, message };
//           } else {
//             Toast.show(message);
//             return Promise.reject({ data, success, message });
//           }
//         }
//       }
//     }
//   },
//   function (error) {
//     return Promise.reject(error);
//   },
// );

export default request;

export const getRefreshTokenFunc = (): Promise<any> => {
  return request.post(
    '/oauth/token',
    qs.stringify({
      client_id: process.env.REACT_APP_CLIENT_ID,
      grant_type: 'refresh_token',
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      refresh_token: getStorage('refresh_token'),
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
};
