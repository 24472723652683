import { request } from '@/utils';
import qs from 'qs';
export * from './help';

// 上传图片
export const uploadImg = (data: any): Promise<any> => {
  return request({
    url: '/zelos-service/problemReport/attachment',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};

export const uploadRequirementImg = (data: any): Promise<any> => {
  return request({
    url: '/zelos-service/requirementReport/attachment',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};

// 查询优先列表
export function getPrecedence(): Promise<any> {
  return request({
    url: '/zelos-service/problemReport/precedence',
    method: 'get',
  });
}

export function reportProblem(data: any) {
  return request<any, CommonRes<string>>({
    url: '/zelos-service/problemReport',
    method: 'post',
    data,
  });
}

export function loadCache(bugMaker: string): Promise<any> {
  return request({
    url: `/zelos-service/problemReport/cache/${bugMaker}`,
    method: 'get',
  });
}

export function saveCache(data: any): Promise<any> {
  return request({
    url: `/zelos-service/problemReport/cache/save`,
    method: 'post',
    data,
  });
}

export function clearCache(bugMaker: string): Promise<any> {
  return request({
    url: `/zelos-service/problemReport/cache/clear/${bugMaker}`,
    method: 'post',
  });
}

export function getDataDictionary(): Promise<any> {
  return request({
    url: `/admin-server/dataDictionary?type=VEHICLE_TAKEOVER_REASON&pageSize=0`,
    method: 'get',
  });
}

export const getDataDictionaryList = (
  type?: string,
  parent?: string,
  parentId?: number,
): Promise<any> => {
  return request.get(
    `/admin-server/dataDictionary?pageSize=0` +
      (type ? `&type=${type}` : '') +
      (parent ? `&parent=${parent}` : '') +
      (parentId ? `&parentId=${parentId}` : ''),
  );
};

export function saveTakeoverReason(data: any): Promise<any> {
  return request({
    url: `/admin-server/vehicleTakeoverReason`,
    method: 'post',
    data,
  });
}

// 问题提报 发生时间 stuck
export function getVehicleStuck(vehicleNo: string): Promise<any> {
  return request({
    url: `/admin-server/vehicleMode/vehicle_stuck/${vehicleNo}`,
    method: 'get',
  });
}

export function reportRequirement(data: any): Promise<any> {
  return request({
    url: '/zelos-service/requirementReport',
    method: 'post',
    data,
  });
}

export const getFlowMessages = (): Promise<any> => {
  return request.get(
    `/admin-server/messageNotification/my_notification?userRead=false&pageNumber=1&pageSize=100&moduleType=PROCESS_MANAGEMENT`,
  );
};

export const getVehicleCaseFlowList = (tplKey?: string): Promise<any> => {
  return request.post(`/admin-server/processDef/list`, {
    pageNumber: 1,
    pageSize: 100,
    data: {
      tplKey,
    },
  });
};

export const getCityByVehlcle = (): Promise<any> => {
  return request.get(`/admin-server/city/by_vehicle_permission`);
};

export const getStationsByVehicle = (
  cityId: number | undefined,
): Promise<any> => {
  return request.get(`/admin-server/station/by_vehicle_permission`, {
    params: {
      cityId,
    },
  });
};

export const getUseCaseList = (params: {
  pageNumber: number;
  pageSize: number;
}): Promise<any> => {
  return request.post('/admin-server/useCase/listWithPermission', {
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
    data: {},
  });
};

export const getVehicleList = (params: {
  name?: string;
  stationId?: number;
  stationIds?: number[];
  cityId?: number;
  useCaseId?: number;
  pageNumber: number;
  pageSize: number;
}): Promise<any> => {
  return request.post('/admin-server/vehicle', {
    pageNumber: params.pageNumber,
    pageSize: params.pageSize,
    data: {
      name: params.name,
      stationIds: params.stationIds,
      stationId: params.stationId,
      cityId: params.cityId,
      useCaseId: params.useCaseId,
    },
  });
};

export const applyProcess = (data: {
  processDefId: number;
  businessData: string;
  remark: string;
}): Promise<any> => {
  return request.post('/admin-server/processInstance/apply', data);
};

export const readMessages = (id: number): Promise<any> => {
  return request.put(`/admin-server/messageNotification/mark_read`, [id]);
};

export const getDetailById = (id: number): Promise<any> => {
  return request.get(`/admin-server/processDef/get_detail_by_id/${id}`);
};

export const getProcessProgress = (id: number): Promise<any> => {
  return request.post(`/admin-server/processInstance/get_approve_by_id/${id}`);
};

export const passProcessApprove = (
  id: number,
  processDefId: number,
): Promise<any> => {
  return request.post(`/admin-server/processApprove/pass`, {
    id,
    processDefId,
  });
};

//驳回审批
export const rejectProcessApprove = (
  id: number,
  processDefId: number,
  remark: string,
): Promise<any> => {
  return request.post(`/admin-server/processApprove/reject`, {
    id,
    processDefId,
    remark,
  });
};

export function getPhenomenonClassificationList(): Promise<any> {
  return request({
    url: '/zelos-service/problemReport/phenomenonClassification',
    method: 'get',
  });
}

export const login = (code: string): Promise<any> => {
  return request.ssoManager.login(code);
};

export const getCheckItems = (data: any): Promise<any> => {
  return request.post('/admin-server/vehicleDailyCheckRecord/todayList', {
    pageNumber: data.pageNumber,
    pageSize: data.pageSize,
    data,
  });
};

export const uploadCheckImg = (data: any): Promise<any> => {
  return request({
    url: '/admin-server/vehicleDailyCheckRecord/uploadPicture',
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
};

export const getCheckDetail = (data: any): Promise<any> => {
  return request.post(
    '/admin-server/vehicleDailyCheckRecord/checkDetails',
    data,
  );
};

export const commitCheckDetails = (data: any): Promise<any> => {
  return request.put(
    '/admin-server/vehicleDailyCheckRecord/updateDetails',
    data,
  );
};

export const batchCommitCheck = (
  date: string,
  vehicleIds: number[],
): Promise<any> => {
  return request.put(
    '/admin-server/vehicleDailyCheckRecord/batch/updateDetails',
    {
      date,
      vehicleIds,
    },
  );
};

export const getOtaBuildHistories = (softwareType: string): Promise<any> => {
  return request.post('/zelos-service/build_histories', {
    softwareType,
  });
};

export const postTrailCar = (data: any): Promise<any> => {
  return request.post('/admin-server/trailerRecord', {
    ...data,
  });
};

export const checkToken = async () =>
  request.post(
    `${process.env.REACT_APP_API_BASE_AUTH}/oauth/check_token`,
    qs.stringify({
      token: localStorage.getItem('access_token'),
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

export const getPermissions = () =>
  request.get<any, CommonRes<string[]>>('/admin-server/user/permissions');

export const getCurrentUserInfo = () => {
  //@ts-ignore
  return request<any, CommonRes<CurrentUserInfoType>>({
    url: '/admin-server/user/current',
    method: 'get',
    messageFree: true,
  });
};
